import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { UserLoggedModule } from "@/apps/2#userLogged/store";
import { SharedModule } from "@/apps/shared/store";
import { AuthModule } from "@/apps/2#auth/store";
import Permissao from "./apps/1#Permissoes/meta";
import Funcionario from "./apps/9#Funcionarios/meta";
import Caixa from "./apps/27#Caixas/meta";
import { formas_de_pagamento_module } from "./apps/28#FormasDePagamento/store";
import Banner from "./apps/37#Banners/meta";
import Endereco from "@/apps/40#Enderecos/meta";
import Produto from "@/apps/41#Produtos/meta";
import ProdutoCategoria from "@/apps/42#ProdutoCategoria/meta";
import Cliente from "@/apps/44#Clientes/meta";
import LinkEQrcode from "@/apps/LinkEQrcode/meta";
import Fornecedor from "@/apps/Fornecedor/meta";
// import Relatorio from "@/apps/Relatorio/meta";
import GestorDePedido from "@/apps/GestorDePedido/meta";
import Lojas from "./apps/0#Lojas/meta";
import Insumos from "./apps/Insumos/meta";
import Transacoes from "./apps/Transacoes/meta";
import Estoque from "./apps/20#Estoques/meta";
import Invoices from "./apps/46#Invoices/meta";
import Planos from "./apps/Planos/meta";
import Seller from "./apps/Seller/meta";
import Withdraw from "./apps/Withdraw/meta";

export const store = new Vuex.Store({
  modules: {
    UserLoggedModule,
    AuthModule,
    SharedModule,
    permissao_module: Permissao.module,
    funcionarios_module: Funcionario.module,
    caixas_module: Caixa.module,
    formas_de_pagamento_module,
    banners: Banner.module,
    endereco_module: Endereco.module,
    produto: Produto.module,
    produto_categoria: ProdutoCategoria.module,
    cliente: Cliente.module,
    mod_linkeqrcode: LinkEQrcode.module,
    mod_forn: Fornecedor.module,
    // mod_relat: Relatorio.module,
    mod_gestor: GestorDePedido.module,
    mod_loja: Lojas.module,
    mod_insumos: Insumos.module,
    mod_transac: Transacoes.module,
    mod_estoque: Estoque.module,
    mod_invoice: Invoices.module,
    mod_planos: Planos.module,
    mod_seller: Seller.module,
    mod_withdraw: Withdraw.module
  }
});

export default store;
