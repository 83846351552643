import http from "@/apps/shared/baseService/config";
import { EventBus } from "@/main.js";

const base = {
  rota: "transacoes",
  nome: "Transações"
};

export const service = {
  criar_public: data => http.post(`/admin/${base.rota}/create`, data),
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar_publico: data => http.post(`/listtransacoes`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data),
  update_status_compra: data => http.post(`/admin/${base.rota}/update-status-compra`, data)
};

const module = {
  actions: {
    listar_transacoes: ({ commit, dispatch, getters }) => {
      console.log("chamou esse");
      dispatch("enableLoading");
      service
        .listar(getters.get_transacoes_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_transacoes", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    listar_transacoes_publicos: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar_publico(getters.get_transacoes_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_transacoes", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_transacao: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({
          ...getters.get_transacao
        })
        .then(res => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualizado!",
            timeout: 6000
          });

          commit("set_transacoes", {
            docs: res.data
          });

          // dispatch("listar_transacoes");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    update_status_compra: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .update_status_compra({
          ...getters.get_transacao
        })
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualizado!",
            timeout: 6000
          });

          dispatch("listar_transacoes");
          EventBus.$emit("status_updated");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_transacao: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({
          ...getters.get_transacao,
          caixa: getters.get_caixa,
          imagens: getters.getLinks,
          links: getters.getFiles
        })
        .then(res => {
          dispatch("disableLoading");
          commit("set_transacao", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Lançamento concluído com sucesso!",
            timeout: 6000
          });

          dispatch("listar_transacoes");
          commit("set_modal_view_transacao", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_transacao: ({ dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_transacoes");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_transacoes: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_transacoes_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_transacoes");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_transacaos: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        commit("set_transacao", payload);
        commit("setFiles", payload.documentos);
      }
      commit("set_modal_view_transacao", true);
    },
    fecha_modal_view_transacao: ({ commit }) => {
      commit("set_transacao", {});
      commit("setLinks", []);
      commit("set_modal_view_transacao", false);
    },
    add_transacao_marcado: ({ commit, getters }, payload) => {
      commit("set_transacoes_marcados", [
        ...getters.get_transacoes_marcados,
        payload
      ]);
    },
    remove_transacao_marcado: ({ commit, getters }, i) => {
      commit(
        "set_transacoes_marcados",
        ...getters.get_transacoes_marcados.filter((item, index) => index !== i)
      );
    }
  },
  state: {
    transacao: {},
    transacoes: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    transacoes_marcados: [],
    transacoes_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_transacao: false
  },
  mutations: {
    set_transacao: (state, payload) => (state.transacao = payload),
    set_transacoes: (state, payload) => (state.transacoes = payload),
    set_transacoes_marcados: (state, payload) =>
      (state.transacoes_marcados = payload),
    set_modal_view_transacao: (state, payload) =>
      (state.modal_view_transacao = payload)
  },
  getters: {
    get_transacao: state => state.transacao,
    get_transacoes: state => state.transacoes,
    get_transacoes_marcados: state => state.transacoes_marcados,
    get_modal_view_transacao: state => state.modal_view_transacao,
    get_transacoes_filtros: state => state.transacoes_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  },
  {
    path: `/proximos`,
    component: () => import("./views/ProximoVencimento.vue"),
    name: "Próximos Vencimentos"
  }
];

export default {
  service,
  module,
  routes
};
