import http from "@/apps/shared/baseService/config";

const base = {
  rota: "menus",
  nome: "Menu"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_menus: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_menus_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_menus", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_menu: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_menu, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_menu", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_menus");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_menu: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_menu, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_menu", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_menus");
          commit("set_modal_view_menu", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_menu: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_menu)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_menus");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_menus: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_menus_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_menus");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_menu: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        commit("set_menu", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_menu", true);
    },
    fecha_modal_view_menu: ({ commit }) => {
      commit("set_menu", {});
      commit("setLinks", []);
      commit("set_modal_view_menu", false);
    },
    add_menu_marcado: ({ commit, getters }, payload) => {
      commit("set_menus_marcados", [...getters.get_menus_marcados, payload]);
    },
    remove_menu_marcado: ({ commit, getters }, i) => {
      commit(
        "set_menus_marcados",
        ...getters.get_menus_marcados.filter((item, index) => index !== i)
      );
    }
  },
  state: {
    menu: {},
    menus: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    menus_marcados: [],
    menus_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_menu: false
  },
  mutations: {
    set_menu: (state, payload) => (state.menu = payload),
    set_menus: (state, payload) => (state.menus = payload),
    set_menus_marcados: (state, payload) => (state.menus_marcados = payload),
    set_modal_view_menu: (state, payload) => (state.modal_view_menu = payload)
  },
  getters: {
    get_menu: state => state.menu,
    get_menus: state => state.menus,
    get_menus_marcados: state => state.menus_marcados,
    get_modal_view_menu: state => state.modal_view_menu,
    get_menus_filtros: state => state.menus_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  },
  {
    path: `/menuonline`,
    component: () => import("./views/MenuOnline.vue"),
    name: "Categorias de Produtos"
  }
];

export default {
  service,
  module,
  routes
};
