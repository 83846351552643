import http from "@/apps/shared/baseService/config";

const base = {
  rota: "marketplaces",
  nome: "Lojas",
  auth: 'erp'
};

export const service = {
  criar: data => http.post(`/${base.auth}/${base.rota}/create`, data),
  listar: data => http.post(`/${base.auth}/${base.rota}/list-my-stores`, data),
  atualizar: data => http.post(`/${base.auth}/${base.rota}/update`, data),
  excluir: data => http.post(`/${base.auth}/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/${base.auth}/${base.rota}/delete-many`, data),
  list_dashboard: data => http.post(`/${base.auth}/${base.rota}/list-dashboard`, data)
};

const module = {
  actions: {
    listar_lojas: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_lojas_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_lojas", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    listar_dashboard: ({ commit, dispatch }) => {
      dispatch("enableLoading");
      service
        .list_dashboard()
        .then(res => {
          dispatch("disableLoading");
          commit("set_dashboard", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_loja: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({
          ...getters.get_loja,
          photos: getters.getLinks,
          links: getters.getFiles
        })
        .then(res => {
          dispatch("disableLoading");
          commit("set_loja", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_lojas");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_loja: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({
          ...getters.get_loja,
          imagens: getters.getLinks,
          links: getters.getFiles
        })
        .then(() => {
          dispatch("disableLoading");
          commit("set_loja", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_lojas");
          commit("set_modal_view_loja", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_loja: ({ dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_lojas");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_lojas: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_lojas_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_lojas");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_loja: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        commit("set_loja", payload);
        commit("setLinks", payload.photos || []);
      }
      commit("set_modal_view_loja", true);
    },
    fecha_modal_view_loja: ({ commit }) => {
      commit("set_loja", {});
      commit("setLinks", []);
      commit("set_modal_view_loja", false);
    },
    add_loja_marcado: ({ commit, getters }, payload) => {
      commit("set_lojas_marcados", [...getters.get_lojas_marcados, payload]);
    },
    remove_loja_marcado: ({ commit, getters }, i) => {
      commit(
        "set_lojas_marcados",
        ...getters.get_lojas_marcados.filter((item, index) => index !== i)
      );
    }
  },
  state: {
    loja: {},
    dashboard: {
      total: 0,
      ultimos_30_dias: 0,
      hoje: 0
    },
    lojas: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    lojas_marcados: [],
    lojas_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_loja: false
  },
  mutations: {
    set_loja: (state, payload) => (state.loja = payload),
    set_dashboard: (state, payload) => (state.dashboard = payload),
    set_lojas: (state, payload) => (state.lojas = payload),
    set_lojas_marcados: (state, payload) => (state.lojas_marcados = payload),
    set_modal_view_loja: (state, payload) => (state.modal_view_loja = payload)
  },
  getters: {
    get_loja: state => state.loja,
    get_dashboard: state => state.dashboard,
    get_lojas: state => state.lojas,
    get_lojas_marcados: state => state.lojas_marcados,
    get_modal_view_loja: state => state.modal_view_loja,
    get_lojas_filtros: state => state.lojas_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  },
  {
    path: `/escolher-empresa`,
    component: () => import("./views/EscolherEmpresa.vue"),
    name: "Escolher Empresa"
  }
];

export default {
  service,
  module,
  routes
};
