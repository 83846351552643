<template>
  <v-container style="flex-direction: column;" fluid class="molde pa-0 ma-0">
    <v-layout style="background: #fff;" column>
      <div class="expande-horizontal centraliza">
        <v-flex v-if="$vuetify.breakpoint.smAndDown" xs12>
          <div class="expande-horizontal">
            <v-app-bar
              :app="$vuetify.breakpoint.smAndDown"
              color="#333"
              :fixed="$vuetify.breakpoint.smAndDown"
              class="elevation-0"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'margin-bottom: 0px; border-radius: 0px'
                  : 'border-radius: 0px;margin-bottom: 0px'
              "
            >
              <v-btn dark @click="drawer = !drawer" icon>
                <v-icon size="18" :color="$theme.primary">mdi-menu</v-icon>
              </v-btn>

              <span
                @click="drawer = !drawer"
                class="fonte text-captalize"
                :style="
                  `color: ${$theme.primary}; font-size: 14pt; cursor: pointer;`
                "
                >{{ title }}</span
              >
              <v-btn
                dark
                rounded
                color="green accent-3"
                class="fonte ml-2 elevation-0"
                @click="$refs.onboarding.open('dashboard')"
                v-if="false"
                small
              >
                <span class="fonteMini">guia</span>
                <v-icon class="ml-1" size="16">mdi-help-circle-outline</v-icon>
              </v-btn>

              <v-spacer></v-spacer>

              <div id="notifications-container">
                <v-menu v-if="false" offset-y left bottom :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      rounded
                      class="animate__animated animate__swing  elevation-0"
                      :color="
                        notificationsUnreadeds.length ? $theme.primary : 'grey'
                      "
                      v-on="on"
                    >
                      <span class="font-weight-bold black--text">
                        {{ notificationsUnreadeds.length }}
                      </span>
                      <v-icon size="21" color="#333">mdi-bell-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-3">
                    <v-list-item class="pa-0">
                      <v-avatar class="mr-3 elevation-1" color="#333">
                        <v-icon :color="$theme.primary">
                          mdi-bell-outline
                        </v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Notificações
                        </v-list-item-title>
                        <span class="fonte fonteMini grey--text">
                          {{ notificationsUnreadeds.length }} Notificações
                          novas</span
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="column centraliza">
                          <v-switch
                            small
                            hide-details
                            class="fonte fonteMini"
                            :color="$theme.primary"
                            v-model="onlyUnreadeds"
                          >
                          </v-switch>
                          <span
                            style="cursor: pointer;"
                            @click="onlyUnreadeds = !onlyUnreadeds"
                            class="fonteMini fonte grey--text"
                          >
                            não lidas
                          </span>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list
                      class="fonte"
                      v-if="notifications.length > 0"
                      style="max-height: 300px; overflow: auto;"
                    >
                      <template
                        v-for="(notification, i) in onlyUnreadeds
                          ? notificationsUnreadeds
                          : notifications"
                      >
                        <v-list-item
                          class="item-list-notification"
                          :key="notification._id"
                          v-if="onlyUnreadeds ? !notification.readed : true"
                          @click="abre_modal_view_notification(notification)"
                        >
                          <v-avatar size="31" class="mr-3" color="#333">
                            <v-icon :color="$theme.primary" size="21">
                              {{ getIconNotification(notification.type) }}
                            </v-icon>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span
                                class=""
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                              >
                                {{
                                  getNotificationDescription(notification.type)
                                }}
                              </span>
                            </v-list-item-title>
                            <span
                              class="fonte grey--text fonteMini mt-1"
                              :class="{
                                'font-weight-bold': !notification.readed
                              }"
                            >
                              {{ notification.description }}
                            </span>
                            <div class="expande-horizontal">
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                {{ $moment(notification.created_at).fromNow() }}
                              </span>
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                v-if="notification.readed"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , foi lida
                                {{
                                  $moment(notification.readDate).format(
                                    "DD/MM/YY [às] HH:mm"
                                  )
                                }}
                              </span>
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                v-else
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , não lida ainda.
                              </span>
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="!notification.readed"
                                >mdi-check</v-icon
                              >
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="notification.readed"
                                >mdi-check-all</v-icon
                              >
                            </div>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            
                          </v-list-item-action> -->
                        </v-list-item>
                        <!-- <v-divider :key="`divider-${i}`"></v-divider> -->
                      </template>
                    </v-list>
                    <div
                      v-if="notifications.length === 0"
                      class="notifications-empty-container fonte expande-horizontal centraliza column"
                    >
                      <v-avatar class="mb-3" :color="$theme.primary">
                        <v-icon color="#f2f2f2">mdi-bell-outline</v-icon>
                      </v-avatar>
                      <span :style="`color: ${$theme.primary}`" class="fonte"
                        >Nada por aqui</span
                      >
                      <span class="text-center grey--text fonteMini fonte">
                        Novas notificações aparecerão aqui
                      </span>
                    </div>
                  </v-card>
                </v-menu>
              </div>

              <div id="options-container">
                <v-menu offset-y v-show="options" left bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-show="options && $vuetify.breakpoint.smAndDown"
                      icon
                      :color="$theme.secondary"
                      v-on="on"
                    >
                      <v-icon :color="$theme.primary">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
  
                  <v-list>
                    <v-list-item
                      v-for="item in options"
                      :key="item.nome"
                      @click="() => item.action()"
                    >
                      <v-list-item-title class="fonte">{{
                        item.nome
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-app-bar>
          </div>
        </v-flex>
        <v-flex v-else xs12 md10>
          <div class="expande-horizontal">
            <v-app-bar
              dense
              app
              dark
              height="39"
              :color="$theme.app_bar"
              :fixed="$vuetify.breakpoint.smAndDown"
              class="elevation-0"
              :class="{
                'vitrify-content': true
              }"
            >
              <v-btn dark @click="drawer = !drawer" icon>
                <v-icon size="19" :color="$theme.primary">mdi-menu</v-icon>
              </v-btn>

              <span
                v-for="(rota, index) in rotas"
                @click="$router.push(rota.path)"
                :key="rota.path"
                :style="
                  `font-size: ${
                    rotas.length > 1 && index === 0 ? '12pt' : '12pt'
                  }; cursor: pointer; color: ${$theme.primary}`
                "
                class="fonte not-selectable text-no-wrap text-lowercase fonteMini"
              >
                {{ rota.name }}
                <v-icon
                  class="pl-0 pr-1"
                  :color="$theme.primary"
                  v-if="index < rotas.length - 1"
                  size="13"
                >
                  mdi-chevron-right
                </v-icon>
              </span>
              <v-spacer></v-spacer>
              <slot name="toolbar-search"></slot>
              <v-spacer></v-spacer>
              <span class="fonte text-capitalize grey--text fonteMini">
                {{ $moment().format("LLLL") }}
                <v-btn
                  rounded
                  color="green accent-3"
                  class="fonte ml-2 elevation-0"
                  @click="$refs.onboarding.open('dashboard')"
                  x-small
                  v-if="false"
                >
                  <span class="fonteMini">guia</span>
                  <v-icon class="ml-1" size="16"
                    >mdi-help-circle-outline</v-icon
                  >
                </v-btn>
              </span>
              <v-spacer></v-spacer>
              <div id="options-container">
                <v-btn
                  small
                  dark
                  v-for="item in options"
                  :color="$theme.primary"
                  class="elevation-0 animated flipInX delay-1s mr-1"
                  @click="item.action()"
                  :key="item.nome"
                >
                  <v-icon size="13" class="mr-1" dark>
                    {{ item.icon }}
                  </v-icon>
                  <span
                    :style="`font-size: 9pt;`"
                    class="fonte fonteMini text-capitalize"
                    >{{ item.nome }}</span
                  >
                </v-btn>
              </div>
              <div id="notifications-container">
                <v-menu v-if="false" offset-y left bottom :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      class="animate__animated animate__swing  elevation-0"
                      :color="
                        notificationsUnreadeds.length ? $theme.primary : 'grey'
                      "
                      v-on="on"
                    >
                      <span class="font-weight-bold black--text">
                        {{ notificationsUnreadeds.length }}
                      </span>
                      <v-icon size="21" color="#333">mdi-bell-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-3">
                    <v-list-item class="pa-0">
                      <v-avatar class="mr-3 elevation-1" color="#333">
                        <v-icon :color="$theme.primary">
                          mdi-bell-outline
                        </v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Notificações
                        </v-list-item-title>
                        <span class="fonte fonteMini grey--text">
                          {{ notificationsUnreadeds.length }} Notificações
                          novas</span
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="column centraliza">
                          <v-switch
                            small
                            hide-details
                            class="fonte fonteMini"
                            :color="$theme.primary"
                            v-model="onlyUnreadeds"
                          >
                          </v-switch>
                          <span
                            style="cursor: pointer;"
                            @click="onlyUnreadeds = !onlyUnreadeds"
                            class="fonteMini fonte grey--text"
                          >
                            não lidas
                          </span>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list
                      class="fonte"
                      v-if="notifications.length > 0"
                      width="350"
                      style="max-height: 300px; overflow: auto;"
                    >
                      <template
                        v-for="(notification, i) in onlyUnreadeds
                          ? notificationsUnreadeds
                          : notifications"
                      >
                        <v-list-item
                          class="item-list-notification"
                          :key="notification._id"
                          v-if="onlyUnreadeds ? !notification.readed : true"
                          @click="abre_modal_view_notification(notification)"
                        >
                          <v-avatar size="31" class="mr-3" color="#333">
                            <v-icon :color="$theme.primary" size="21">
                              {{ getIconNotification(notification.type) }}
                            </v-icon>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span
                                class=""
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                              >
                                {{
                                  getNotificationDescription(notification.type)
                                }}
                              </span>
                            </v-list-item-title>
                            <span
                              class="fonte grey--text fonteMini mt-1"
                              :class="{
                                'font-weight-bold': !notification.readed
                              }"
                            >
                              {{ notification.description }}
                            </span>
                            <div class="expande-horizontal">
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                {{
                                  $moment(notification.created_at).fromNow()
                                }}</span
                              >
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                v-if="notification.readed"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , foi lida
                                {{
                                  $moment(notification.readDate).format(
                                    "DD/MM/YY [às] HH:mm"
                                  )
                                }}
                              </span>
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                v-else
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , não lida ainda.
                              </span>
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="!notification.readed"
                                >mdi-check</v-icon
                              >
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="notification.readed"
                                >mdi-check-all</v-icon
                              >
                            </div>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            
                          </v-list-item-action> -->
                        </v-list-item>
                        <!-- <v-divider :key="`divider-${i}`"></v-divider> -->
                      </template>
                    </v-list>
                    <div
                      v-if="notifications.length === 0"
                      class="notifications-empty-container fonte expande-horizontal centraliza column"
                    >
                      <v-avatar class="mb-3" :color="$theme.primary">
                        <v-icon color="#f2f2f2">mdi-bell-outline</v-icon>
                      </v-avatar>
                      <span :style="`color: ${$theme.primary}`" class="fonte"
                        >Nada por aqui</span
                      >
                      <span class="text-center grey--text fonteMini fonte">
                        Novas notificações aparecerão aqui
                      </span>
                    </div>
                  </v-card>
                </v-menu>
              </div>

              <v-menu offset-y left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    class="animate__animated ml-1 elevation-0"
                    color="grey darken-3"
                    v-on="on"
                  >
                    <span class="font-weight-bold orange--text">
                      {{ getLoggedUser.tenant[0].nome.split(" ")[0] }}
                    </span>
                    <v-icon color="orange">mdi-storefront</v-icon>
                  </v-btn>
                </template>

                <v-list nav dense>
                  <v-list-item to="/logged-user">
                    <v-list-item-title>{{
                      getLoggedUser.nome
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="logout">
                    <v-list-item-title>sair</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu offset-y v-show="options" left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-show="options && $vuetify.breakpoint.smAndDown"
                    icon
                    :color="$theme.secondary"
                    v-on="on"
                  >
                    <v-icon :color="$theme.secondary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dark class="fonte">
                  <v-list-item
                    v-for="item in options"
                    :key="item.nome"
                    @click="() => item.action()"
                  >
                    <v-list-item-title class="fonte">{{
                      item.nome
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-app-bar>
          </div>
        </v-flex>
      </div>

      <v-navigation-drawer
        :mini-variant="$vuetify.breakpoint.smAndUp ? true : false"
        :expand-on-hover="$vuetify.breakpoint.smAndUp ? true : false"
        app
        v-model="drawer"
      >
        <v-card
          class="elevation-0"
          color="transparent"
          style="border-radius: 0px; margin-bottom: 0px;"
        >
          <div class="semQuebra pa-3 pr-4 pl-4  centraliza expande-horizontal">
            <!-- <img style="width: 90%;" src="img/logo.png" alt="" /> -->
            <img style="width: 50px;" src="img/logo_mini.png" alt="" />
            <!-- <img style="width: 40%;" :src="$theme.logo" alt="" /> -->
          </div>
        </v-card>

        <v-list style="height: 74vh;" nav dense>
          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/dashboard"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/dashboard' ? 'white' : $theme.icon_drawer
              "
            >
              mdi-chart-box-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/dashboard' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Dashboard
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/funcionarios"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/funcionarios' ? 'white' : $theme.icon_drawer
              "
            >
              mdi-account-circle
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/funcionarios' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Gestores
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/clientes"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/clientes' ? 'white' : $theme.icon_drawer
              "
            >
              mdi-account-circle-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/clientes' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Clientes do Gestor
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/clientes"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/clientes' ? 'white' : $theme.icon_drawer
              "
            >
              mdi-basket-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/clientes' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Vendas do Gestor
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/clientes"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/clientes' ? 'white' : $theme.icon_drawer
              "
            >
              mdi-basket-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/clientes' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Vendas de Planos
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/planos"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="$route.path === '/planos' ? 'white' : $theme.icon_drawer"
            >
              mdi-cash-multiple
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/planos' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Planos
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/vendedores"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="$route.path === '/vendedores' ? 'white' : $theme.icon_drawer"
            >
              mdi-account-group
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/vendedores' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Vendedores
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/saques"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="$route.path === '/saques' ? 'white' : $theme.icon_drawer"
            >
              mdi-cash-refund
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/saques' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Saques
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/funcionarios"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="$route.path === '/funcionarios' ? 'white' : $theme.icon_drawer"
            >
              mdi-lock
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/funcionarios' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Usuários erp
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/planos"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="$route.path === '/planos' ? 'white' : $theme.icon_drawer"
            >
              mdi-cash
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/planos' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Solicitações de saque
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/planos"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="$route.path === '/planos' ? 'white' : $theme.icon_drawer"
            >
              mdi-account-wrench-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/planos' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Reclamações
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list>
      </v-navigation-drawer>

      <v-main class="molde" :style="`background: ${$theme.background};`">
        <div class="expande-horizontal centraliza">
          <v-flex xs12 md12>
            <div class="expande-horizontal wrap">
              <div
                v-if="$vuetify.breakpoint.smAndUp"
                class="expande-horizontal px-2 py-1 pt-0"
              >
              </div>
              <slot></slot>
            </div>
          </v-flex>
        </div>
      </v-main>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["title", "options", "icon", "action", "origem", "rotas"],
  data() {
    return {
      drawer: true,
      getNotifications: {
        received: []
      }
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedUser",
      "get_permission_user"
      // 'getNotifications'
    ])
  },
  methods: {
    ...mapActions([
      // 'listNotifications',
      "logout"
    ]),
    listNotifications() {},
    goBack() {
      if (this.action !== undefined) {
        this.action();
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.drawer = this.$vuetify.breakpoint.smAndUp;
    this.listNotifications();
  }
};
</script>

<style>
.centraliza-tudo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.molde {
  display: flex;
  width: 100%;
}
</style>
