import http from "@/apps/shared/baseService/config";

const base = {
  auth: 'erp',
  rota: "plans",
  nome: "Planos"
};

export const service = {
  criar: data => http.post(`/${base.auth}/${base.rota}/create`, data),
  listar: data => http.post(`/${base.auth}/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/${base.auth}/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/${base.auth}/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_plans: ({ commit, dispatch, getters }) => {
      if (localStorage.getItem("plans")) {
        commit("set_plans", JSON.parse(localStorage.getItem("plans")));
      } else {
        dispatch("enableLoading");
      }
      service
        .listar(getters.get_plans_filtros)
        .then(res => {
          dispatch("disableLoading");
          localStorage.setItem("plans", JSON.stringify(res.data));
          commit("set_plans", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_plan: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_plan, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_plan", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_plans");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_plan: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_plan, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_plan", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_plans");
          commit("set_modal_view_plan", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_plan: ({ dispatch }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_plans");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_plan: ({ commit }, payload) => {
      if (payload) {
        commit("set_plan", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_plan", true);
    },
    fecha_modal_view_plan: ({ commit }) => {
      commit("set_plan", {});
      commit("setLinks", []);
      commit("set_modal_view_plan", false);
    }
  },
  state: {
    plan: {
      features: []
    },
    plans: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    plans_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_plan: false
  },
  mutations: {
    set_plan: (state, payload) => (state.plan = payload),
    set_plans: (state, payload) => (state.plans = payload),
    set_modal_view_plan: (state, payload) =>
      (state.modal_view_plan = payload)
  },
  getters: {
    get_plan: state => state.plan,
    get_plans: state => state.plans,
    get_modal_view_plan: state => state.modal_view_plan,
    get_plans_filtros: state => state.plans_filtros
  }
};

const routes = [
  {
    path: `/planos`,
    component: () => import("./views/Main.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
