import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import { dashboard_routes } from "./apps/dashboard/router";
import { logged_user_routes } from "./apps/2#userLogged/router";
import { auth_routes } from "./apps/2#auth/router";
import Permissao from "./apps/1#Permissoes/meta";
import Funcionario from "./apps/9#Funcionarios/meta";
import { tipos_de_despesas_routes } from "./apps/26#TiposDeDespesa/router";
import Caixa from "./apps/27#Caixas/meta";
import { formas_de_pagamento_routes } from "./apps/28#FormasDePagamento/router";
import Banner from "./apps/37#Banners/meta";
import Endereco from "./apps/40#Enderecos/meta";
import MenuOnline from "./apps/MenuOnline/meta";
import Produto from "./apps/41#Produtos/meta";
import ProdutoCategoria from "./apps/42#ProdutoCategoria/meta";
import Cliente from "./apps/44#Clientes/meta";
import LinkEQrcode from "./apps/LinkEQrcode/meta";
import ConfigurarPedido from "./apps/ConfigurarPedido/meta";
import Fornecedor from "./apps/Fornecedor/meta";
import ConfirurarEmpresa from "./apps/ConfigurarEmpresa/meta";
import Relatorio from "./apps/Relatorio/meta";
import GestorDePedido from "./apps/GestorDePedido/meta";
import Lojas from "./apps/0#Lojas/meta";
import Insumo from "./apps/Insumos/meta";
import Transacoes from "./apps/Transacoes/meta";
import Invoices from "./apps/46#Invoices/meta";
import Planos from "./apps/Planos/meta";
import Seller from "./apps/Seller/meta";
import Withdraw from "./apps/Withdraw/meta";

const routes = [
  ...dashboard_routes,
  ...logged_user_routes,
  ...auth_routes,
  ...Permissao.routes,
  ...Funcionario.routes,
  ...tipos_de_despesas_routes,
  ...Caixa.routes,
  ...formas_de_pagamento_routes,
  ...Banner.routes,
  ...Endereco.routes,
  ...MenuOnline.routes,
  ...Produto.routes,
  ...ProdutoCategoria.routes,
  ...Cliente.routes,
  ...LinkEQrcode.routes,
  ...ConfigurarPedido.routes,
  ...Fornecedor.routes,
  ...ConfirurarEmpresa.routes,
  ...Relatorio.routes,
  ...GestorDePedido.routes,
  ...Lojas.routes,
  ...Insumo.routes,
  ...Transacoes.routes,
  ...Invoices.routes,
  ...Planos.routes,
  ...Seller.routes,
  ...Withdraw.routes,
];

export const router = new VueRouter({
  mode: process.env.CORDOVA_PLATFORM ? "hash" : "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
