import Service from "../service";
import { router } from "@/router";

export const actions = {
  listarFormasDePagamentos: ({ commit, dispatch }, page) => {
    dispatch("enableLoading");

    Service.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setFormasDePagamentos", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  criarFormaDePagamento({ commit, dispatch, getters }) {
    dispatch("enableLoading");

    if (getters.getFormaDePagamento._id) {
      Service.update(getters.getFormaDePagamento)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualizado com sucesso!",
            timeout: 6000
          });
          commit("setModalViewFormaDePagamento", false);
          dispatch("listarFormasDePagamentos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    } else {
      Service.create(getters.getFormaDePagamento)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro concluído com sucesso!",
            timeout: 6000
          });
          commit("setModalViewFormaDePagamento", false);
          dispatch("listarFormasDePagamentos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    }
  },
  abreModalViewFormaDePagamento({ commit, dispatch }, payload) {
    if (payload._id) {
      commit("setFormaDePagamento", payload);
      commit("setModalViewFormaDePagamento", true);
    } else {
      commit("setFormaDePagamento", {});
      commit("setModalViewFormaDePagamento", true);
    }
  },
  fechaModalViewFormaDePagamento({ commit }) {
    commit("setModalViewFormaDePagamento", false);
  }
  // marcarUmaDisciplina({commit, getters}, disciplina) {
  //   commit('setFormasDePagamentosMarcadas', [...getters.getDisciplinasMarcadas, disciplina])
  // },
  // desmarcaUmaDisciplina({commit, getters}, disciplina) {
  //   commit('setFormasDePagamentosMarcadas', ...getters.getDisciplinasMarcadas.filter(p => p._id !== disciplina._id))
  // }
};

export default {};
