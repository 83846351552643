var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expande-horizontal centraliza column"},[_c('h3',{staticClass:"fonte text-center"},[_vm._v("Arquivos")]),_c('v-list',[_vm._l((_vm.getFiles),function(file,index){return [_c('v-list-item',{key:file.nome},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":_vm.$theme.primary}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-archive")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(file.name)+" ")]),_c('v-list-item-subtitle',[_c('a',{attrs:{"target":"_blank","href":file.link}},[_vm._v(" ACESSE O ARQUIVO ")]),_vm._v(" {{}} ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.createConfirmAction({
                icon: 'mdi-warning',
                color: 'yellow darken-3',
                message: 'Remover este arquivo?',
                action: 'removeFile',
                action_value: index
              })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1),_c('v-divider',{key:index})]})],2),_c('div',{staticClass:"input-wrapper",staticStyle:{"display":"flex","height":"240px","align-items":"center"}},[_vm._m(0),_c('input',{staticStyle:{"cursor":"pointer"},attrs:{"id":"input-file2","type":"file","name":"importxml"},on:{"change":_vm.upload}}),_c('span',{attrs:{"id":"file-name2"}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"input-file2"}},[_c('span',{staticClass:"fonte black--text mr-1"},[_vm._v("Adicionar Um Arquivo")])])
}]

export { render, staticRenderFns }