import http from "@/apps/shared/baseService/config";

const base = {
  rota: "produtocategorias",
  nome: "Categoria de Produtos"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_produtocategorias: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_produtocategorias_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_produtocategorias", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_produtocategoria: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({
          ...getters.get_produtocategoria,
          imagens: getters.getLinks
        })
        .then(res => {
          dispatch("disableLoading");
          commit("set_produtocategoria", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_produtocategorias");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_produtocategoria: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_produtocategoria, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_produtocategoria", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_produtocategorias");
          commit("set_modal_view_produtocategoria", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_produtocategoria: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_produtocategoria)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_produtocategorias");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_produtocategorias: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_produtocategorias_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_produtocategorias");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_produtocategoria: (
      { commit, getters, dispatch },
      payload
    ) => {
      if (payload) {
        commit("set_produtocategoria", payload);
        commit("setLinks", payload.imagens || []);
      }
      commit("set_modal_view_produtocategoria", true);
    },
    fecha_modal_view_produtocategoria: ({ commit }) => {
      commit("set_produtocategoria", {});
      commit("setLinks", []);
      commit("set_modal_view_produtocategoria", false);
    },
    add_produtocategoria_marcado: ({ commit, getters }, payload) => {
      commit("set_produtocategorias_marcados", [
        ...getters.get_produtocategorias_marcados,
        payload
      ]);
    },
    remove_produtocategoria_marcado: ({ commit, getters }, i) => {
      commit(
        "set_produtocategorias_marcados",
        ...getters.get_produtocategorias_marcados.filter(
          (item, index) => index !== i
        )
      );
    }
  },
  state: {
    produtocategoria: {},
    produtocategorias: {
      docs: []
    },
    produtocategorias_marcados: [],
    produtocategorias_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_produtocategoria: false
  },
  mutations: {
    set_produtocategoria: (state, payload) =>
      (state.produtocategoria = payload),
    set_produtocategorias: (state, payload) =>
      (state.produtocategorias = payload),
    set_produtocategorias_marcados: (state, payload) =>
      (state.produtocategorias_marcados = payload),
    set_modal_view_produtocategoria: (state, payload) =>
      (state.modal_view_produtocategoria = payload)
  },
  getters: {
    get_produtocategoria: state => state.produtocategoria,
    get_produtocategorias: state => state.produtocategorias,
    get_produtocategorias_marcados: state => state.produtocategorias_marcados,
    get_modal_view_produtocategoria: state => state.modal_view_produtocategoria,
    get_produtocategorias_filtros: state => state.produtocategorias_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
