export const mutations = {
  setModalViewFormaDePagamento(state, payload) {
    state.modalViewFormaDePagamento = payload;
  },
  setFormasDePagamentos(state, payload) {
    state.formasDePagamentos = payload;
  },
  setFormaDePagamento(state, payload) {
    state.formaDePagamento = payload;
  },
  setFormaDePagamentoMarcadas(state, payload) {
    state.formaDePagamentoMarcadas = payload;
  }
};

export default {};
