import Vue from "vue";
import store from "@/store";
import App from "./App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import mask from "vue-the-mask";
import moment from "moment";
import "moment/locale/pt-br";
import "@/apps/shared";
import { db, storage } from "./apps/shared/firebase";
import LineIcon from "./apps/shared/components/LineIcon";
import Helper from "./helper";
import VueHtml2pdf from "vue-html2pdf";
import VuejsClipper from "vuejs-clipper";
import VueRx from 'vue-rx'
import run from "./apps/shared/Onoff";

export const EventBus = new Vue();

Vue.use(VueHtml2pdf);
Vue.use(mask);
Vue.use(VueRx)
Vue.use(VuejsClipper);
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;

const theme = {
  dark: false,
  primary: "#4A148C",
  secondary: "#4A148C",
  third: "#3EB4DF",
  icon_drawer: "#363636",
  icon_color: "#e1673c",
  background: "#fff",
  grey: "#f2f2f2",
  drawer: "#e1673c",
  logo:
    // "https://firebasestorage.googleapis.com/v0/b/egestor-876cd.appspot.com/o/logo.png?alt=media&token=cb370788-7daf-4242-b9f7-c38649394779",
    "https://upload.wikimedia.org/wikipedia/commons/d/d4/ROCKET_logo.svg",
  token_img: ""
};

Vue.component("icon", LineIcon);

Vue.prototype.$theme = theme;
Vue.prototype.$db = db;
Vue.prototype.$storage = storage;
Vue.prototype.$helper = new Helper();
Vue.prototype.$run = run;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
