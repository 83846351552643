import http from "@/apps/shared/baseService/config";

const base = {
  rota: "banners",
  nome: "Banners"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_banners: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_banners_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_banners", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_banner: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({
          ...getters.get_banner,
          imagens: getters.getLinks,
          links: getters.getFiles
        })
        .then(res => {
          dispatch("disableLoading");
          commit("set_banner", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_banners");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_banner: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({
          ...getters.get_banner,
          imagens: getters.getLinks,
          links: getters.getFiles
        })
        .then(() => {
          dispatch("disableLoading");
          commit("set_banner", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_banners");
          commit("set_modal_view_banner", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_banner: ({ dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_banners");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_banners: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_banners_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_banners");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_banner: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        commit("set_banner", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_banner", true);
    },
    fecha_modal_view_banner: ({ commit }) => {
      commit("set_banner", {});
      commit("setLinks", []);
      commit("set_modal_view_banner", false);
    },
    add_banner_marcado: ({ commit, getters }, payload) => {
      commit("set_banners_marcados", [
        ...getters.get_banners_marcados,
        payload
      ]);
    },
    remove_banner_marcado: ({ commit, getters }, i) => {
      commit(
        "set_banners_marcados",
        ...getters.get_banners_marcados.filter((item, index) => index !== i)
      );
    }
  },
  state: {
    banner: {},
    banners: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    banners_marcados: [],
    banners_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_banner: false
  },
  mutations: {
    set_banner: (state, payload) => (state.banner = payload),
    set_banners: (state, payload) => (state.banners = payload),
    set_banners_marcados: (state, payload) =>
      (state.banners_marcados = payload),
    set_modal_view_banner: (state, payload) =>
      (state.modal_view_banner = payload)
  },
  getters: {
    get_banner: state => state.banner,
    get_banners: state => state.banners,
    get_banners_marcados: state => state.banners_marcados,
    get_modal_view_banner: state => state.modal_view_banner,
    get_banners_filtros: state => state.banners_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
