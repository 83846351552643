import http from "@/apps/shared/baseService/config";

const base = {
  rota: "gestordepedidos",
  nome: "Gestor de Pedidos"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/transacoes/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_gestordepedidos: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_gestordepedidos_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_gestordepedidos", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_gestordepedido: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_gestordepedido, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_gestordepedido", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_gestordepedidos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_gestordepedido: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_gestordepedido, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_gestordepedido", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_gestordepedidos");
          commit("set_modal_view_gestordepedido", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_gestordepedido: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_gestordepedido)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_gestordepedidos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_gestordepedidos: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_gestordepedidos_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_gestordepedidos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_gestordepedido: (
      { commit, getters, dispatch },
      payload
    ) => {
      commit("set_gestordepedido", payload);
      commit("set_modal_view_gestordepedido", true);
    },
    fecha_modal_view_gestordepedido: ({ commit }) => {
      commit("set_modal_view_gestordepedido", false);
      setTimeout(() => {
        commit("set_gestordepedido", {});
      }, 300);
    },
    add_gestordepedido_marcado: ({ commit, getters }, payload) => {
      commit("set_gestordepedidos_marcados", [
        ...getters.get_gestordepedidos_marcados,
        payload
      ]);
    },
    remove_gestordepedido_marcado: ({ commit, getters }, i) => {
      commit(
        "set_gestordepedidos_marcados",
        ...getters.get_gestordepedidos_marcados.filter(
          (item, index) => index !== i
        )
      );
    }
  },
  state: {
    gestordepedido: {},
    gestordepedidos: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    gestordepedidos_marcados: [],
    gestordepedidos_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_gestordepedido: false
  },
  mutations: {
    set_gestordepedido: (state, payload) => (state.gestordepedido = payload),
    set_gestordepedidos: (state, payload) => (state.gestordepedidos = payload),
    set_gestordepedidos_marcados: (state, payload) =>
      (state.gestordepedidos_marcados = payload),
    set_modal_view_gestordepedido: (state, payload) =>
      (state.modal_view_gestordepedido = payload)
  },
  getters: {
    get_gestordepedido: state => state.gestordepedido,
    get_gestordepedidos: state => state.gestordepedidos,
    get_gestordepedidos_marcados: state => state.gestordepedidos_marcados,
    get_modal_view_gestordepedido: state => state.modal_view_gestordepedido,
    get_gestordepedidos_filtros: state => state.gestordepedidos_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
