import http from "./config";

const getAuth = "erp";

const run = async (route, payload, auth) => {
  // if () {

  // }

  return http.post(`${auth ? auth : getAuth}/${route}`, payload);
};

export default run;
